var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card mb-3",staticStyle:{"width":"100%"},attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_vm._m(0),_c('el-form',{ref:"form",attrs:{"model":_vm.settingsData}},[_c('el-row',{staticClass:"sidemenu"},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"card-body "},[_c('div',{staticClass:"p-4"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Expiration Settings")]),_c('p',[_vm._v(" Set the expiry date for your document validity so the system does it for you automatically. ")]),_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v("Expires in")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","min":"1","type":"number","size":"medium"},model:{value:(
                      _vm.settingsData.expiration_settings
                        .expire_documents_in_days
                    ),callback:function ($$v) {_vm.$set(_vm.settingsData.expiration_settings
                        , "expire_documents_in_days", $$v)},expression:"\n                      settingsData.expiration_settings\n                        .expire_documents_in_days\n                    "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1),_c('div',{staticClass:"pl-1"},[_vm._v("After document is sent.")])]),_c('div',{staticClass:"alert alert-info d-inline-block"},[_vm._v(" You can adjust default expiration settings for any particular document. ")]),_c('div',{staticClass:"expiration-reminder-check"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settingsData.expiration_settings
                        .send_expiration_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settingsData.expiration_settings
                        , "send_expiration_reminder_status", $$v)},expression:"\n                      settingsData.expiration_settings\n                        .send_expiration_reminder_status\n                    "}}),_c('div',{staticClass:"px-2",staticStyle:{"max-width":"200px"}},[_vm._v(" Send first reminder ")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","min":"1","type":"number","disabled":!_vm.settingsData.expiration_settings
                          .send_expiration_reminder_status,"size":"medium"},model:{value:(
                        _vm.settingsData.expiration_settings
                          .send_first_reminder_in_days
                      ),callback:function ($$v) {_vm.$set(_vm.settingsData.expiration_settings
                          , "send_first_reminder_in_days", $$v)},expression:"\n                        settingsData.expiration_settings\n                          .send_first_reminder_in_days\n                      "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1),_c('div',{staticClass:"pl-1"},[_vm._v("Before expiration")])],1)]),_c('div',{staticClass:"expiration-reminder-check"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settingsData.expiration_settings
                        .repeat_expiration_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settingsData.expiration_settings
                        , "repeat_expiration_reminder_status", $$v)},expression:"\n                      settingsData.expiration_settings\n                        .repeat_expiration_reminder_status\n                    "}}),_c('div',{staticClass:"px-2"},[_vm._v("Repeat reminder")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","min":"1","type":"number","disabled":!_vm.settingsData.expiration_settings
                          .repeat_expiration_reminder_status,"size":"medium"},model:{value:(
                        _vm.settingsData.expiration_settings
                          .repeat_expiration_reminder
                      ),callback:function ($$v) {_vm.$set(_vm.settingsData.expiration_settings
                          , "repeat_expiration_reminder", $$v)},expression:"\n                        settingsData.expiration_settings\n                          .repeat_expiration_reminder\n                      "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1),_c('div',{staticClass:"pl-1"},[_vm._v("Before expiration")])],1)])])]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-4"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v(" Auto reminders settings ")]),_c('p',[_vm._v(" Automatically send email reminders to signers regarding the incompletion of document. ")]),_c('div',{staticClass:"d-flex w-100 align-items-center mb-1"},[_c('el-checkbox',{model:{value:(
                    _vm.settingsData.auto_remainders_settings
                      .first_reminder_status
                  ),callback:function ($$v) {_vm.$set(_vm.settingsData.auto_remainders_settings
                      , "first_reminder_status", $$v)},expression:"\n                    settingsData.auto_remainders_settings\n                      .first_reminder_status\n                  "}}),_c('div',{staticClass:"px-2",staticStyle:{"max-width":"200px"}},[_vm._v(" Send first reminder in ")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","type":"number","min":"0","disabled":!_vm.settingsData.auto_remainders_settings
                        .first_reminder_status,"size":"medium"},model:{value:(
                      _vm.settingsData.auto_remainders_settings
                        .send_first_reminder_in_days
                    ),callback:function ($$v) {_vm.$set(_vm.settingsData.auto_remainders_settings
                        , "send_first_reminder_in_days", $$v)},expression:"\n                      settingsData.auto_remainders_settings\n                        .send_first_reminder_in_days\n                    "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1)],1),_c('div',{staticClass:"expiration-reminder-check"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settingsData.auto_remainders_settings
                        .repeat_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settingsData.auto_remainders_settings
                        , "repeat_reminder_status", $$v)},expression:"\n                      settingsData.auto_remainders_settings\n                        .repeat_reminder_status\n                    "}}),_c('div',{staticClass:"px-2"},[_vm._v("Repeat reminder")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","type":"number","min":"0","disabled":!_vm.settingsData.auto_remainders_settings
                          .repeat_reminder_status,"size":"medium"},model:{value:(
                        _vm.settingsData.auto_remainders_settings.repeat_reminder
                      ),callback:function ($$v) {_vm.$set(_vm.settingsData.auto_remainders_settings, "repeat_reminder", $$v)},expression:"\n                        settingsData.auto_remainders_settings.repeat_reminder\n                      "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1),_c('div',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Days" : "Until completed & expired")+" ")])],1)]),_c('div',{staticClass:"alert alert-info d-inline-block"},[_vm._v(" You can adjust default expiration settings for any particular document. ")])])])],1)],1),_c('div',{staticClass:"card-footer bg-white"},[_c('el-form-item',{staticClass:"mb-0 p-2"},[_c('el-button',{on:{"click":_vm.goToDashboard}},[_vm._v("Cancel")]),_c('el-button',{staticClass:"type-2",staticStyle:{"background-color":"#409eff","color":"#ffffff"},on:{"click":_vm.updateUserSettings}},[_vm._v("Save Changes")])],1)],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header bg-light "},[_c('h3',{staticClass:"title fw-normal mb-0 p-2"},[_vm._v(" Document Expiration & Reminders ")])])}]

export { render, staticRenderFns }